import '../css/main.css';
function Company() {
    return (
        <section class="section">
            <div class="container">
                <div class="row row--relative">
                    <div class="col-12">
                        <div class="company">
                            <h2 class="company__title">Company registration</h2>
                            <div class="row">
                                <div class="col-12 col-xl-7">
                                    <p class="company__text">Integrity, reliability, transparency, flexibility and
                                        communication should be the core values of any company that works in the field of
                                        investment attraction. Centure adheres to absolutely all of these values so that our
                                        clients can have confidence and trust in us.</p>
                                    <p class="company__text">Name: <b>BrokenAI LLC</b></p>
                                    <p class="company__text">Entity ID. <b>2024-001458887</b></p>
                                </div>

                                <div class="col-12 col-xl-4 offset-xl-1">
                                    <p class="company__subtitle">Legal address:</p>
                                    <p class="company__text">5830 E 2ND ST, STE 7000 #15996, <br />CASPER, <br />WY 8260999 Chancery Street</p>
                                </div>
                            </div>
                            <span class="block-icon block-icon--orange">
                                <i class="ti ti-certificate-2"></i>
                            </span>
                            <span class="screw screw--lines-bl"></span>
                            <span class="screw screw--lines-br"></span>
                            <span class="screw screw--lines-tr"></span>
                        </div>
                    </div>
                    <div class="section__canvas section__canvas--second" id="canvas2"></div>
                </div>
            </div>
        </section>

    );
}

export default Company;
