import '../css/main.css';
import Company from './Company';
function Home() {
  return (
    <div>
      <section class="hero">
        <div class="container">
          <div class="row">
            <div class="col-12 col-lg-7">
              <div class="hero__content hero__content--first">
                <h1 class="hero__title"><strong>BrokenAI OffSec</strong> <br />Penetrate, Protect and Prevail.
                </h1>

                <div class="hero__btns">
                  <a href="/contact" class="hero__btn">Contact Us</a>
                  {/* <a href="about.html" class="hero__btn hero__btn--light">About us</a> */}
                </div>
              </div>
            </div>

            <div class="col-12 col-lg-5">
              <div class="hero__content hero__content--second">
                <a href="/contact" class="cta">
                  <h2 class="cta__title">Summer Offer</h2>
                  <p class="cta__text">Contact us and get our services <b>upto 50%
                    off</b> this
                    summer! The number of clients is limited.</p>

                  <div class="progressbar">
                    <h3 class="progressbar__title">Clients:</h3>
                    <div class="progress" role="progressbar" aria-label="Animated striped" aria-valuenow="7"
                      aria-valuemin="0" aria-valuemax="10">
                      <div class="progress-bar progress-bar-striped progress-bar-animated"
                        style={{ width: '75%' }}><span>7</span></div>
                    </div>
                    <div class="progressbar__values">
                      <span class="progressbar__value progressbar__value--left">0</span>
                      <span class="progressbar__value progressbar__value--right">10</span>
                    </div>
                  </div>
                  <span class="block-icon block-icon--red">
                    <i class="ti ti-gift"></i>
                  </span>
                  <span class="screw screw--lines-bl"></span>
                  <span class="screw screw--lines-br"></span>
                  <span class="screw screw--lines-tr"></span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* statics */}
      <div class="section">
        <div class="container">
          <div class="row">
            <div class="col-12 col-sm-6 col-xl-3">
              <div class="stats">
                <span class="stats__value">42</span>
                <p class="stats__name">Clients</p>

                <span class="stats__dodger stats__dodger--left stats__dodger--purple"></span>
                <span class="stats__dodger stats__dodger--right stats__dodger--purple"></span>
              </div>
            </div>

            <div class="col-12 col-sm-6 col-xl-3">
              <div class="stats">
                <span class="stats__value">500+</span>
                <p class="stats__name">Vulnerabilities Detected</p>

                <span class="stats__dodger stats__dodger--left stats__dodger--orange"></span>
                <span class="stats__dodger stats__dodger--right stats__dodger--orange"></span>
              </div>
            </div>

            <div class="col-12 col-sm-6 col-xl-3">
              <div class="stats">
                <span class="stats__value">99%</span>
                <p class="stats__name">Client Satisfaction</p>

                <span class="stats__dodger stats__dodger--left stats__dodger--green"></span>
                <span class="stats__dodger stats__dodger--right stats__dodger--green"></span>
              </div>
            </div>

            <div class="col-12 col-sm-6 col-xl-3">
              <div class="stats">
                <span class="stats__value">3</span>
                <p class="stats__name">InfoSec Events</p>

                <span class="stats__dodger stats__dodger--left stats__dodger--blue"></span>
                <span class="stats__dodger stats__dodger--right stats__dodger--blue"></span>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* features */}
      <section class="section">
        <div class="container">
          <div class="row">
            <div class="col-12 col-md-8 offset-md-2 col-lg-6 offset-lg-3">
              <div class="section__title">
                <h2>Our Features</h2>
                <p>We belief that offensive tactics are essential to a robust
                  defense. By thinking like
                  attackers, we provide insights and solutions that traditional defensive measures might
                  overlook.</p>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-12 col-lg-4">
              <div class="feature">
                <h3 class="feature__title">Attack</h3>
                <p class="feature__text">We simulate real-world attacks to identify vulnerabilities in your
                  systems
                </p>

                <span class="block-icon block-icon--orange">
                  <i class="ti ti-shield-dollar"></i>
                </span>
                <span class="screw screw--bl"></span>
                <span class="screw screw--tr"></span>
                <span class="screw screw--big-br"></span>
              </div>
            </div>

            <div class="col-12 col-md-6 col-lg-4">
              <div class="feature">
                <h3 class="feature__title">Protect</h3>
                <p class="feature__text">
                  We provide solutions to secure your systems and prevent future attacks.
                </p>

                <span class="block-icon block-icon--green">
                  <i class="ti ti-24-hours"></i>
                </span>
                <span class="screw screw--bl"></span>
                <span class="screw screw--tr"></span>
                <span class="screw screw--big-br"></span>
              </div>
            </div>

            <div class="col-12 col-md-6 col-lg-4">
              <div class="feature">
                <h3 class="feature__title">Safeguard</h3>
                <p class="feature__text">
                  We provide ongoing monitoring and support to ensure your systems remain secure.

                </p>

                <span class="block-icon block-icon--blue">
                  <i class="ti ti-chart-histogram"></i>
                </span>
                <span class="screw screw--bl"></span>
                <span class="screw screw--tr"></span>
                <span class="screw screw--big-br"></span>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Company />
    </div>
  );
}

export default Home;
