import '../css/splide.min.css';
import '../css/bootstrap.min.css';

import '../webfont/tabler-icons.min.css'
import { Link } from 'react-router-dom';
import '../css/splide.min.css';
import '../css/bootstrap.min.css';
import '../webfont/tabler-icons.min.css';


function Footer() {
    return (
        <footer className="footer">
            <div className="container">
                {/* <div className="row">
                        <div className="col-12 col-sm-8 col-md-6 col-lg-6 col-xl-4 order-1 order-lg-4 order-xl-1">
                            <div className="footer__logo">
                                <img src={logo} alt="" />
                            </div>

                            <p className="footer__tagline">The BrokenAI OffSec team is a group of professionals who are
                                passionate about their work. We are constantly improving our skills and knowledge to provide
                                you with the best service.
                            </p>

                            <div className="footer__currencies">
                                <i className="ti ti-currency-bitcoin"></i>
                                <i className="ti ti-currency-ethereum"></i>
                                <i className="ti ti-currency-litecoin"></i>
                                <i className="ti ti-currency-solana"></i>
                                <i className="ti ti-currency-dogecoin"></i>
                            </div> 
                        </div>

                        <div
                            className="col-6 col-md-4 col-lg-3 col-xl-2 order-3 order-md-2 order-lg-2 order-xl-3 offset-md-2 offset-lg-0">
                            <h6 className="footer__title">Company</h6>
                            <div className="footer__nav">
                                <Link to="/about">About BrokenAI</Link>
                                <Link to="/blog">Our news</Link>
                                <Link to="/license">License</Link>
                                <Link to="/contact">Contact</Link>
                            </div>
                        </div>

                        <div className="col-12 col-md-8 col-lg-6 col-xl-4 order-2 order-md-3 order-lg-1 order-xl-2">
                            <div className="row">
                                <div className="col-12">
                                    <h6 className="footer__title">Services & Features</h6>
                                </div>

                                <div className="col-6">
                                    <div className="footer__nav">
                                        <Link to="/affiliate">Affiliate</Link>
                                        <Link to="/contest">Contest</Link>
                                    </div>
                                </div>

                                <div className="col-6">
                                    <div className="footer__nav">
                                        <Link to="/safety">Safety</Link>
                                        <Link to="/analytics">Analytics</Link>
                                        <Link to="/reports">Reports</Link>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-6 col-md-4 col-lg-3 col-xl-2 order-4 order-md-4 order-lg-3 order-xl-4">
                            <h6 className="footer__title">Support</h6>
                            <div className="footer__nav">
                                <Link to="/faq">Help center</Link>
                                <Link to="/privacy">Privacy policy</Link>
                                <Link to="/terms">Terms & conditions</Link>
                            </div>
                        </div>
                    </div> */}

                <div className="row">
                    <div className="col-12">
                        <div className="footer__content">
                            <div className="footer__social">
                                <Link to="https://www.github.com/the-broken-ai"><i className="ti ti-brand-github"></i></Link>

                                <Link to="https://twitter.com/CheaterPeter0"><i className="ti ti-brand-x"></i></Link>

                                <Link to="https://www.linkedin.com/company/101361957/admin/feed/posts/"><i className="ti ti-brand-linkedin"></i></Link>
                            </div>

                            <small className="footer__copyright">© BrokenAI, 2024</small>
                        </div>
                    </div>
                </div>
            </div>

            <span className="screw screw--footer screw--footer-bl"></span>
            <span className="screw screw--footer screw--footer-br"></span>
            <span className="screw screw--footer screw--footer-tr"></span>
            <span className="screw screw--footer screw--footer-tl"></span>
        </footer>
    );
}


export default Footer;
