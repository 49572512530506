import '../css/splide.min.css';
import '../css/bootstrap.min.css';
import '../webfont/tabler-icons.min.css';
import logo from '../img/logo.svg';
import { Link } from 'react-router-dom';
import React, { useState, useEffect, useRef } from 'react';

function Header() {
  const [isNavActive, setIsNavActive] = useState(false);
  const [isHeaderActive, setIsHeaderActive] = useState(false);
  const headerRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsHeaderActive(true);
      } else {
        setIsHeaderActive(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    handleScroll(); // Initial check

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const toggleNav = () => {
    setIsNavActive(!isNavActive);
    if (window.scrollY === 0) {
      setIsHeaderActive(!isHeaderActive);
    }
  };

  return (
    <header className={`header ${isHeaderActive ? 'header--active' : ''}`} ref={headerRef}>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="header__content">
              <button
                className={`header__btn ${isNavActive ? 'header__btn--active' : ''}`}
                type="button"
                aria-label="header__nav"
                id="header__btn"
                onClick={toggleNav}
              >
                <span></span>
                <span></span>
                <span></span>
              </button>
              <Link to="/" className="header__logo">
                <img src={logo} alt="" />
              </Link>
              <span className="header__tagline">BrokenAI<br />OffSec</span>
              <ul className={`header__nav ${isNavActive ? 'header__nav--active' : ''}`} id="header__nav">
                <li>
                  <Link to="/">Home</Link>
                </li>


                <li>
                  <Link to="/contact">Contact</Link>
                </li>

                <li>
                  <a href="https://blog.brokenai.ca">Blog</a>
                </li>

                <li class="header__dropdown">
                  <a class="dropdown-link" href="/#" role="button" data-bs-toggle="dropdown"
                    aria-expanded="false">Pages <i class="ti ti-point-filled"></i></a>

                  <ul class="dropdown-menu header__dropdown-menu">
                    <li>
                      <Link to="/contact">Get in touch</Link>
                    </li>
                    {/* <li><a href="faq.html">FAQ</a></li> */}
                  </ul>
                </li>

                {/* <li class="header__dropdown">
                  <a class="dropdown-link dropdown-link--menu" href="/#" role="button"
                    data-bs-toggle="dropdown" aria-expanded="false"><i class="ti ti-dots"></i></a>

                  <ul class="dropdown-menu header__dropdown-menu">
                    <li><a href="404.html">404 Page</a></li>
                    <li><a href="privacy.html">Privacy policy</a></li>
                  </ul>
                </li> */}
              </ul>
              {/* ... rest of the header content ... */}
              <div class="header__language">
                <a class="dropdown-link" href="/#" role="button" data-bs-toggle="dropdown"
                  aria-expanded="false">EN <i class="ti ti-point-filled"></i></a>

                <ul class="dropdown-menu header__language-menu">
                  <li><Link to="/">English</Link></li>
                  {/* <li><a href="/#">Spanish</a></li>
                  <li><a href="/#">French</a></li> */}
                </ul>
              </div>

              {/* <Link to="/profile" class="header__profile">
                <i class="ti ti-user-circle"></i>
                <span>Profile</span>
              </Link> */}
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;